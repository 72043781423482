const { DateTime } = require('luxon');

export function lsTest() {
    let test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
}

export function parseQuery(url) {
    let query = url.split('?')[1];
    if (query) {
        let o = {};
        let pairs = query.split('&');
        pairs.forEach(pair => {
            if (pair.split('=')[0].includes('[]')) {
                o[pair.split('=')[0].split('[]')[0]] = o[pair.split('=')[0].split('[]')[0]] ? o[pair.split('=')[0].split('[]')[0]] : [];
                o[pair.split('=')[0].split('[]')[0]].push(decodeURIComponent(pair.split('=')[1]));
            } else {
                o[pair.split('=')[0]] = decodeURIComponent(pair.split('=')[1])
            }
        })
        return o;
    } else {
        return {}
    }
}

export function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function queryCheck(query) {
    query = query.trim();
    let su_parts1 = query.split('_');
    let su_parts2 = query.split(':');
    if (su_parts1.length > 1 && su_parts2.length > 1) {
        query = query;
    } else {
        query = query.replaceAll("'", '');

        query = query.replaceAll(":", ' ');
    }
    return query;
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

export function formatPrice(price) {
    price = price.toString();
    if (price.length > 3) {
        return [price.slice(0, -3), " ", price.slice(-3)].join('')
    } else {
        return price;
    }
}

export function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
}

export function msToTime(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;
    if (mins < 10) mins = "0" + mins;
    if (secs < 10) secs = "0" + secs;
    if (hrs > 0) {
        return hrs + ':' + mins + ':' + secs;
    } else {
        return mins + ':' + secs;
    }
}

export function format_number(number, separator = ' ') {
    number = '' + number;
    let digits = number.split('').reverse();
    let formatted = [];
    digits.forEach((digit, index) => {
        if ((index + 1) % 3 === 0 && index + 1 !== digits.length) {
            formatted.push(separator + digit);
        } else {
            formatted.push(digit);
        }
    })
    return formatted.reverse().join('');
}

export function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

export function nl2br(str, is_xhtml) {
    let breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1'+ breakTag +'$2');
}
